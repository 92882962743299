import React from 'react'
import {graphql} from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import ProjectSingle from '../components/project-single'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import {
  toPlainText,
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutCatsWithoutParents
} from '../lib/helpers'

export const query = graphql`
  query ProjectTemplateQuery($id: String!) {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      instagramLink
      facebookLink
      footer
      description
      twitter
      title
      email
      linkedInLink
      author
      keywords
      ccLink
      ccImage {
        ...SanityImage
        alt
      }
      image {
        ...SanityImage
        alt
      }
    }
    cats: allSanityCategory(sort: {fields: [title], order: ASC}, filter: {title: {ne: null}, }) {
      edges {
        node {
          title
          slug {
            current
          }
          parents {
            id
          }
        }
      }
    }
    project: sanityOtherProjects(id: {eq: $id}) {
      id
      publishedAt
      image {
        desktopImage {
          ...SanityImage
          alt
          caption
        }
        mobileImage {
          ...SanityImage
          alt
          caption
        }
      }
      images {
            ...SanityImage
            alt
            caption
          }
      title
      projectType
      embed
      pdf {
        title
        file {
          asset {
            url
          }
        }
      }
      webLink
      video {
        url
      }
      slug {
        current
      }
      _rawDescription(resolveReferences: {maxDepth: 5})
    }
  }
`

const ProjectTemplate = props => {
  const {data, errors} = props

  const site = (data || {}).site

  const catNodes = (data || {}).cats
    ? mapEdgesToNodes(data.cats)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutCatsWithoutParents)
    : []

  const project = data && data.project

  let pimage = '';

  if(project.images[0]) {
    pimage = project.images[0].desktopImage;
  }

  //console.log('project');
  //console.log(project);

  return (
    <Layout catNodes={catNodes} title={site.title} email={site.email} footer={site.footer} instagramLink={site.instagramLink} facebookLink={site.facebookLink} twitter={site.twitter} linkedInLink={site.linkedInLink} ccImage={site.ccImage} ccLink={site.ccLink} >
      {errors && <SEO title='GraphQL Error' />}
      {project && <SEO title={project.title || 'Untitled'} author={site.author} description={toPlainText(project._rawDescription)} image={pimage} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      {project && 
        <ProjectSingle {...project} />
      }
    </Layout>
  )
}

export default ProjectTemplate