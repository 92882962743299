import React from 'react'
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import {format} from 'date-fns'
import PortableText from './portableText'
import Container from './container'
import '../styles/project-single.css'
import {Link} from 'gatsby'
import SimpleReactLightbox from "simple-react-lightbox"
import { SRLWrapper } from "simple-react-lightbox";
import ReactPlayer from 'react-player'

function ProjectSingle (props) {
  const {_rawDescription, publishedAt, image, images, embed, projectType, title, webLink, pdf, video} = props
  //console.log(images.length)


  const options = {
    thumbnails: {
      showThumbnails: false
    },
    buttons: {
      showDownloadButton: false,
      showNextButton: false,
      showPrevButton: false
    }
  };

  if(images.length > 1) {
    options.buttons.showNextButton = true
    options.buttons.showPrevButton = true
  }

  return (
    <Container>

    <div className="wrapper">
        <h3><Link to="/projects/">Projects</Link></h3>

        <h2>{title}</h2>

    </div>


    <SimpleReactLightbox>

    <div className="grid">

        {image.desktopImage && image.desktopImage.asset && image.mobileImage && image.mobileImage.asset && (
          <div className="project-single-banner width-12-12-m width-12-12">
          <div class="info">
            <div class="inner">
              <div><h2>{title}</h2></div>
              <div><h3>{projectType}</h3></div>
            </div>
          </div>

          <div className="image-holder">
            <picture>
              <source 
                media="(max-width: 699px)" 
                srcset={imageUrlFor(buildImageObj(image.mobileImage))
                  .width(1000)
                  .height(Math.floor((1 / 1) * 1000))
                  .auto('format')
                  .url()}
              />
              <source 
                media="(min-width: 700px)" 
                srcset={imageUrlFor(buildImageObj(image.desktopImage))
                  .width(2000)
                  .height(Math.floor((1 / 2) * 2000))
                  .auto('format')
                  .url()}
              />
              <img
                src={imageUrlFor(buildImageObj(image.desktopImage))
                  .width(2000)
                  .height(Math.floor((1 / 2) * 2000))
                  .auto('format')
                  .url()}
                alt={image.desktopImage.alt}
                title={image.desktopImage.alt}
              />
            </picture>
          </div>
          </div>
        )}

    
    </div>


      <div className="wrapper no-top-margin">
        <div id="project-single" className="project-single">

            <div className="grid">


              <div className="width-12-12-m single">

                <div className="date-tag-holder">
                <div class="date-holder">
                  <span className="date">
                    {format(new Date(publishedAt), 'MMMM Do, YYYY')}
                  </span>
                </div>
                </div>

                {props._rawDescription && (
                  <div className="body-holder">

                    {_rawDescription && <PortableText blocks={_rawDescription} />}

                    {pdf && pdf.file && 
                        <p><a href={pdf.file.asset.url} rel="noopener noreferrer" target="_blank">{pdf.title} (pdf)</a></p>
                    }

                    {webLink && 
                        <p className="extras"><a href={webLink} rel="noopener noreferrer" target="_blank">View Project</a></p>
                    }

                  </div>
                )}

                {video && video.url && (
                  <div className="video-holder">
                    <div className='player-wrapper'>
                      <ReactPlayer 
                        className='react-player'
                        url={video.url}
                        width='100%'
                        height='100%'
                      />
                    </div>
                  </div>
                )}

                {props.embed && 
                <div className="embed">
                <iframe src={embed} 
                        title="artwork embed"
                        width="100%" 
                        height="480"
                        seamless="seamless" 
                        scrolling="no" 
                        frameBorder="0" 
                        allowFullScreen>
                </iframe>
                </div>
              }

                <SRLWrapper options={options}>

                  <div className="grid">

                  {images && images.map(image => {

                    return image && image.asset && (

                    <div className="gallery-image width-6-12 width-6-12-m">
                    <a data-attribute="SRL" href={imageUrlFor(buildImageObj(image))
                          .width(2000)
                          .url()}
                    >
                      <img
                        src={imageUrlFor(buildImageObj(image))
                          .width(800)
                          .height(800)
                          .fit('crop')
                          .url()}
                        alt={image.alt}
                      />
                      </a>
                    </div>
                  )
                  })}

                  </div>

              </SRLWrapper>

          </div>

          </div>

        </div>
      </div>
    </SimpleReactLightbox>
    </Container>
  )
}

export default ProjectSingle